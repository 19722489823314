import React from 'react';
import LoaderComponent from './loader.style';

const Loader = ({size, inline}) => {

    if (!size) {
        size = 200;
    }
    const scale = (size/200);
    size=200;

    return (
        <div style={{scale: scale}}>
            <LoaderComponent>
                <svg className="isoContentLoader" viewBox={"0 0 "+(size/4)+" "+(size/4)}>
                    <circle
                        className="isoContentLoaderCircle"
                        cx={(size/8)}
                        cy={(size/8)}
                        r={(size/10)}
                        fill="none"
                        strokeWidth={(3.6)*(size/200)}
                    />
                </svg>
            </LoaderComponent>
        </div>
    );
}

export default Loader;