import {BaseApi} from "./BaseApi";
import ICustomer from "./models/ICustomer";


export class ProjectApi extends BaseApi {

  public getCustomer(searchTerm: string): Promise<ICustomer[]> {
    return this.requestGet('/dccockpit/customers?searchterm='+ searchTerm);
  }

  public createCustomer(customer: any): Promise<ICustomer[]> {
    return this.requestPost('/dccockpit/customers', customer);
  }


    getAvailableHousesForUser() {
      return this.requestGet('/dccockpit/customers/gchouses');
    }

    getAvailableKeyAcccounts() {
      return this.requestGet('/dccockpit/customers/keyaccounts');
    }

  getCustomerProducts(projectId: string) {
    return this.requestGet('/dccockpit/customers/products/'+projectId);
  }

  getAvailableUsers() {
    return this.requestGet('/dccockpit/customers/users');
  }

  checkCustomer(customerID: string) {
    return this.requestGet('/dccockpit/customers/check/'+customerID);
  }

  updateCustomer(customer: any) {
    return this.requestPut('/dccockpit/customers/'+customer.Id, customer);
  }
}

export default new ProjectApi();
