

export const getExistingConfiguration = (item) => {

    const existingField = getCustomField(item, "configForExisting", true);
    if (existingField) {
        if (existingField.model) {
            return existingField;
        }
    }

    return false;
}

export const getIdFromLink = (link) => {
    if (link) {
        const parts = link.split("cid=");
        if (parts.length > 0) {
            try {
                return parts[parts.length-1].split("&")[0];
            }
            catch(e) {
                return parts[parts.length-1];
            }
        }
    }
    return null;
}

export const getConfignameFromConfig = (config) => {
    if (config) {
        if (config.configurator_name) {
            return config.configurator_name;
        }
        // the previewLink is like http(s)://configname-config.xyz-fhw-seite.de/xxxx
        // i want to extract the "configname" out of the link
        const link = config.link;
        if (link) {
            const parts = link.split("://");
            if (parts.length > 1) {
                return parts[1].split("-")[0];
            }
        }
    }
    return null;
}

export const allContractsInproject = (project, company) => {
    const list = [

    ];
    try {

        for (const q of project.quotations) {
            for (const pos of q.positions) {
                if (pos.article) {
                    // If article has contract
                    const contracts = getCustomField(pos.article, "contracts", true);
                    if (contracts) {
                        for (const contract of contracts) {
                            // When contract is not in list
                            if (!list.find((c) => c.url == contract.url)) {
                                list.push({
                                    ...contract,
                                    checked: false
                                });
                            }
                        }
                    }
                }
            }
        }

        if (project.SubProjects) {
            for (const sub of project.SubProjects) {
                list.push(...allContractsInproject(sub, company));
            }
        }

    }
    catch(e) {

    }
    return list;
}

export const getCustomFieldString = (product, type, defval=null) => {
    if (product && product.CustomFields) {
        const cf = product.CustomFields.find((d) => d.Name.toLowerCase().includes(type.toLowerCase()) || d.Id.toString() == type.toString());
        if (cf) {
            const v =  cf.Value;
            if (defval && (!v || v.length <= 0)) {
                return defval;
            }
            return v;
        }
    }
    return defval ? defval : [];
}

export const getCustomField = (product, type, jsonConvert=false, defval=null) => {
    if (product && product.CustomFields) {
        const cf = product.CustomFields.find((d) => d.Name.toLowerCase().includes(type.toLowerCase()) || d.Id.toString() == type.toString());
        if (cf) {
            if (jsonConvert == true) {
                try {
                    return JSON.parse(cf.Value);
                }
                catch(e) {

                }
                return null;
            }
            const v =  cf.Value.split(",");
            if (defval) {
                if (v.length > 0) {
                    if (v[0]) {
                        return v[0];
                    }
                    return defval;
                }
            }
            return v;
        }
    }
    return defval ? defval : [];
}

export const getCustomFieldOne = (product, type, jsonConvert=false, defval=null) => {
    const d = getCustomField(product, type, jsonConvert, defval);
    if (d) {
        if (d.length > 0) {
            return d[0];
        }
    }
    return defval;
}

export const hasCustomField = (product, type) => {
    return getCustomField(product, type).length > 0;
}

function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    const parsed_user = JSON.parse(jsonPayload);
    for (const k of Object.keys(parsed_user)) {
        if (k.includes("/")) {
            const newk = k.split("/").slice(-1).join("");
            parsed_user[newk] = parsed_user[k];
        }
    }
    return parsed_user;
};

export const hasRole = (role) => {
    const user = getUser();
    if (user) {
        if (user.roles) {
            return user.roles.includes(role);
        }
    }
    return false;
}

export const getUser = () => {
    const u = localStorage.getItem("access_token");
    if (u) {
        return parseJwt(u);
    }
    return null;
}