import { EventEmitter } from 'events';
import Auth0Lock from 'auth0-lock';
import history from '@iso/lib/helpers/history';
import Auth0Config from '@iso/config/auth0.config';
import { notification } from '@iso/components';
import Https from "../../../../packages/isomorphic-cra/src/services/HttpsClient"

class Auth0 extends EventEmitter {
  lock = new Auth0Lock(
    Auth0Config.clientID,
    Auth0Config.domain,
    Auth0Config.options
  );

  login = () => {
    if (!this.lock) {
      return;
    }
    //this.lock.show();
     this.redirectToLogin();
  };

  reAuthenticate() {

    const audience = "u20";
    let callback_url = window.location.protocol+"//"+window.location.host + "/auth0loginCallback";
    window.location.href = "https://agenturid.eu.auth0.com/authorize?response_type=token&scope=https://agenturid.com user_metadata app_metadata openid profile email&client_id=Sv08pnUlLUulbwkSHMZdRknclG7YX3dC&redirect_uri="+callback_url+"&state=STATE&audience="+audience;


  }

  redirectToLogin() {
    // const queryString = window.location.hash;
    // const urlParams = new URLSearchParams(queryString.replace(/#/g,"?"));
    // const error = urlParams.get("error");
    // if (error) {
    //
    // }
    // else {
      let callback_url = window.location.protocol+"//"+window.location.host + "/auth0loginCallback";
      if (window.location.hostname.includes("localhost")) {

        callback_url = "http://localhost:"+window.location.port+"/auth0loginCallback";
      }

      const state = JSON.stringify({
        hash: window.location.hash,
        host: window.location.host
      });
      const audience = "u20";
      window.location = "https://agenturid.eu.auth0.com/authorize?state="+encodeURIComponent(state)+"&response_type=token&scope=https://agenturid.com user_metadata app_metadata openid profile email&client_id=Sv08pnUlLUulbwkSHMZdRknclG7YX3dC&redirect_uri="+callback_url+"&audience="+audience;
    // }
  }

  getCurrentHashParameter(hashParameterName, hash) {
    // console.log(window.location.hash)
    let theURL = new URL(window.location.origin); // create dummy url
    theURL.search = hash.substring(1);



    return theURL.searchParams.get(hashParameterName);
  }

  handleAuthentication = (hash) => {
    // Add callback Lock's `authenticated` event
    const accessToken = this.getCurrentHashParameter("access_token", hash);
    const state = this.getCurrentHashParameter("state", hash);
    if (accessToken && state) {
      let jsonData = null;
      try {
        jsonData = JSON.parse(state);
      }
      catch(e) {}

      Https.setAccessToken(accessToken);
      this.setSession({accessToken: accessToken, ...jsonData})
      return accessToken;
      // We need to save the AccessToken and redirect
      // saveAccessToken(accessToken);
      // setTimeout(() => {
      //   if (window.location.hostname.includes("localhost")) {
      //     /**
      //      * This is a special case - due to the allowed auth0 redirect urls we need to rewrite the url locally to the correct one
      //      * before saving the access token. because otherwise localstorage on the correct url is then "null" and its an endless
      //      * loop
      //      */
      //     if (!window.location.hostname.includes(".")) {
      //       window.location = window.location.protocol+"//"+jsonData.host+window.location.hash;
      //       return;
      //     }
      //     jsonData.host = jsonData.host;
      //   }
      //   window.location = window.location.protocol+"//"+jsonData.host+jsonData.hash;
      //   window.location.reload();
      // }, 200);
    }
    // return;
    //
    // this.lock.on('authenticated', this.setSession);
    // // Add callback for Lock's `authorization_error` event
    // this.lock.on('authorization_error', error =>
    //   notification('error', 'Wrong mail or password')
    // );
  };
  setSession = authResult => {
    // Set the time that the access token will expire at
    let expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    );
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.accessToken);
    localStorage.setItem('expires_at', expiresAt);
    // navigate to the dashboard route

    let hostname = (window.location.hostname.includes("localhost")) ? "http://"+window.location.hostname+":"+window.location.port : "https://"+window.location.hostname;
    window.location.href = hostname + "/dashboard"
     // history.replace('/dashboard');
  };
  logout = () => {
    // Clear access token and ID token from local storage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    // navigate to the home route
   // history.replace('/');

    if (typeof window !== "undefined") {
      //clearAccessToken();
      let hostname = (window.location.hostname.includes("localhost")) ? "http://"+window.location.hostname+":"+window.location.port : "https://"+window.location.hostname;
      setTimeout(() => window.location.href = "https://agenturid.eu.auth0.com/v2/logout?client_id=Sv08pnUlLUulbwkSHMZdRknclG7YX3dC&&returnTo="+hostname, 300);
    }
  };

  isAuthenticated = () => {
    let expiresAt = JSON.parse(localStorage.getItem('expiresAt'));
    return new Date().getTime() < expiresAt;
  };
}
export default new Auth0();
