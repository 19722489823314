import React, {useState} from 'react';
import {DeleteOutlined, InfoCircleFilled, InfoCircleOutlined} from "@ant-design/icons";
import {Col, Input, Row} from "antd";
import {getCustomField, getExistingConfiguration, hasCustomField} from "./helper";
import Modal from "@iso/components/Feedback/Modal";
import ExistingElementSelector from "./ExistingElementSelector";



const ShoppingCart = ({getShoppingCartTransformed,summarizeServicePositions, deletable=true,
                       removeItem, amountChanged, amountChangeableAllowed, getNetPrice,
                       hidePrices, showPaymentOptions=false, hideGroupArticles=false,
                       showTitle=true,
    selectedCustomer=null,
                       choosePaymentOptions=false, setPaymentOption = null,
                       showPreview=false, showExistingSelection=true, setExistingElement = null}) => {

    const taxFactor = 1.19;

    const hasPriceInfo = (product, price, index) => {

        let textInfo = "";
        const text = getCustomField(product.article, "Zahlungskonditionen");
        if (text && text.length > 0) {
            const data = text[0].split("\n");
            textInfo = (data.length >= index+1) ? data[index] : null;
        }

        return textInfo && textInfo.length > 0;
    }

    const getNetPriceLocal = () => {
        if (getNetPrice) {
            return getNetPrice();
        }
        var price = 0;
        for (const product of getShoppingCartTransformed()) {
            if (product.IsOptional === true) continue;
            const optionalField = getCustomField(product, "Optional");
            if (optionalField && optionalField.length > 0 && optionalField[0].toLowerCase() === "true") continue;

            let localPrice =  product.TotalUnitPrice * product.Amount;
            if (product.Discount) {
                localPrice = localPrice * (1 - product.Discount / 100);
            }
            price += localPrice;
        }
        return price;
    }

    const showPriceInfo = (product, price, index) => {
        let textInfo = "";
        const text = getCustomField(product.article, "Zahlungskonditionen");
        if (text && text.length > 0) {
            const data = text[0].split("\n");
            textInfo = (data.length >= index+1) ? data[index] : null;
        }

        Modal.info({
            title: 'Preisinformationen',
            content: textInfo,
            okText: 'Ok',
            onOk: () => {

            }

        });
    }

    const getMinAmount = (product) => {
        if (product.article) {
            const prices = product.article.prices;
            if (prices && prices.length > 0) {
                return prices[0].Amount;
            }
        }
        return 1;
    }

    const onChangeArticleItem = (item, e) => {
        if (amountChanged) {
            const newamount = parseInt(e.target.value);
            if (newamount) {
                amountChanged(item, newamount);
            }
            else {
                amountChanged(item, item.Amount);
            }
        }
    }

    const changePriceOptions = (product, priceOptions) => {

        setPaymentOption(product, priceOptions);
    }

  let amountChangeable = amountChangeableAllowed;

  return (
    <div style={{marginLeft: 0, paddingLeft: 15}}>
        {showTitle && <div style={{fontSize: 20, fontWeight: "bold"}}>
        Ihre Bestellung
      </div>}

      <div>
        {getShoppingCartTransformed()
            .filter((p) => !hideGroupArticles || (!p.article || (p.article && getCustomField(p.article, "groupArticle", false, "False") != "True")))
            .map(item => {
                const hasExistingElement = showExistingSelection && getExistingConfiguration(item);
                const config = hasCustomField(item, "config") ? getCustomField(item, "config", true) : null;
                const link = config ? config.previewLink : null;
                let showAmountField = false;
                if (amountChangeable) {
                        const field = getCustomField(item, "amountChangeable");
                        if (field && field.length > 0) {
                            showAmountField = field[0].toLowerCase() === "true";
                        }
                }
                if (!item.priceOptionId && item.article && item.article.prices && item.article.prices.length > 1) {
                    item.priceOptionId = item.article.prices[0].Id;
                }

                if (item.Type == "Textline") {
                    return (
                        <Row style={{marginTop: "10px", marginBottom: "25px", marginLeft: 55}}>
                            <Col lg={showPreview ? 12 : 12} xl={showPreview ? 12 : 12}  style={{fontSize: 16}} md={showPreview ? 12 : 12} >
                                <span dangerouslySetInnerHTML={{__html: item.Text}}></span>
                            </Col>
                        </Row>
                    );
                }

                return (
                    <Row style={{marginTop: "10px", marginBottom: "5px"}}>
                      <Col lg={showPreview ? 12 : 12} xl={showPreview ? 12 : 12}  style={{fontSize: 16}} md={showPreview ? 12 : 12} >
                          {<span><Input onChange={(e) => onChangeArticleItem(item, e)}
                                                            disabled={!showAmountField}
                                        min={getMinAmount(item)}
                                                           style={{display: "inline",
                                                               backgroundColor: "white",
                                                               maxWidth: "50px", border: "none", borderBottom: "1px solid #ccc"}}
                                                           size={"small"} value={item.Amount} type={"number"} />x </span>}
                          <span dangerouslySetInnerHTML={{__html: "  "+item.Text}}></span>
                          {deletable && <div style={{float: "right", cursor: "pointer"}} onClick={() => {
                              removeItem(item)
                          }
                          }><DeleteOutlined /></div>}
                      </Col>
                        {showPreview && <Col lg={6} xl={6} md={6}>
                            { link && <a target={"_blank"} href={link}>Vorschaulink öffnen</a>}
                        </Col>}
                      {!hidePrices && <Col lg={showPreview ? 6 : 12} md={showPreview ? 6 : 12} xl={showPreview ? 6 : 12}
                                           style={{fontSize: 16, textAlign: "right"}}>
                          {item.Discount > 0 && <span style={{}}>{new Number(item.TotalUnitPrice*item.Amount*(1-(item.Discount/100))).toFixed(2)} &euro;<br/></span>}
                          <span style={{textDecoration: item.Discount > 0 ? "line-through" : "none", fontSize: item.Discount > 0 ? 12 : 16}}>{new Number(item.TotalUnitPrice*item.Amount).toFixed(2)} &euro;</span>


                      </Col>
                      }
                        {showPaymentOptions  &&  parseInt(item.TotalPrice) > 0 &&  item.article && item.article.prices && item.article.prices.length > 1 && <Col lg={8} xl={8} md={8}>
                            Zahlungsmodalitäten
                      </Col>}
                        {showPaymentOptions  && parseInt(item.TotalPrice) > 0 && item.article && item.article.prices && item.article.prices.length > 1 && <Col lg={16} xl={16} md={16} style={{display: "flex"}}>
                            {item.article.prices.filter((p) => p.Price > 0).map((price, index) => {
                               return <div style={{marginRight: "15px"}}><label>{choosePaymentOptions &&
                                   <input checked={price.Id === item.priceOptionId} value={price.Id === item.priceOptionId} name={"payment_"+item.Id} onClick={() => changePriceOptions(item,price)} type={"radio"} />}&nbsp;{price.Amount}x {new Number(price.Price).toFixed(2)} &euro;
                                   {hasPriceInfo(item, price, index) && <InfoCircleOutlined style={{paddingLeft: 4}} onClick={() => showPriceInfo(item, price, index)} className={"hover-blue"} />}</label></div>
                            })}
                        </Col>}
                        {hasExistingElement && <Col md={18}>
                            {hasExistingElement.text ? <label>{hasExistingElement.text}</label> : null}
                            <ExistingElementSelector value={item.existingElement}
                                                     kind={hasExistingElement.model}
                                                     customerId={selectedCustomer ? selectedCustomer.Id : null}
                                                     onSelection={(id) => setExistingElement ? setExistingElement(item, id) : null} />
                        </Col>}
                    </Row>


                )
        })}
      </div>
      <div>
        {!hidePrices && <div style={{borderTop: "2px solid grey", paddingTop: 20, marginTop: 20}}>
          <div style={{flex: 1,display: "flex", alignItems: "flex-end", fontSize: 20}}>
            <div style={{flex: 1, fontSize: 20, textAlign: "end"}}>Netto</div>
            <div style={{flex: 1, fontSize: 20, textAlign: "end"}}>{new Number(getNetPriceLocal()).toFixed(2) + " €"} </div>
          </div>


          <div style={{flex: 1, display: "flex", fontSize: 20}}>
            <div style={{flex: 1, fontSize: 20, fontWeight: "bold", textAlign: "end"}}>Brutto</div>
            <div style={{flex: 1, fontSize: 20, fontWeight: "bold", textAlign: "end"}}>{new Number(getNetPriceLocal() * taxFactor).toFixed(2) +" €"} </div>
          </div>
        </div>}
      </div>
    </div>
  )

}

export default ShoppingCart;
