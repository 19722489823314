import {Checkbox, Col, Row, Spin} from "antd";
import ShoppingCart from "./ShoppingCart";
import basicStyle from "@iso/assets/styles/constants";
import React, {useEffect, useMemo, useState} from 'react';
import Loader from "@iso/components/utility/loader";
import {allContractsInproject, getCustomField} from "./helper";
import Handlebars from "handlebars";
import UploadOrUploaded from "./UploadOrUploaded";
import ProjectService from "../services/ProjectService";




const OrderApprovementComp = ({projectId, company,loading,removeChecks, onChecked, approved, validated, project, setPaymentOption} ) =>  {

  const { rowStyle, colStyle } = basicStyle;
  const allContracts = allContractsInproject(project, company);
  const [contractChecked, setContractChecked] = useState(allContracts);
  const [dsgvoChecked, setDsgvoChecked] = useState(false)
  const [returnPoliciesChecked, setReturnPoliciesChecked] = useState(false)

  const allChecked = (list) => {
    for (const item of list) {
      if (!item.checked && (typeof item.required === "undefined" || item.required === true)) {
        if (item.uploadRequired === true) {

        }
        return false;
      }
    }
    return true;
  }

  const onContractCheckedChanged = (contractCheckedElement, checked) => {


    if (contractCheckedElement.targetField) {
      contractCheckedElement.loading = true;
      setTimeout(() => {
        ProjectService.setOrderApprovementField(
            project,
            company,
            contractCheckedElement,
            checked
        )
            .then(() => {
              contractCheckedElement.loading = false;
              setContractChecked([...contractChecked]);
            })
            .catch(() => {
                contractCheckedElement.checked = false;
                contractCheckedElement.loading = false;
                setContractChecked([...contractChecked]);
            })
      }, 1000)
    }

    setContractChecked([...contractChecked]);

  }

  const replaceUrl = (url) => {
    // replace handlebars object
    const data = {
      company: company
    }
    if (url.includes("{")) {
      var template = Handlebars.compile(url ? url : '');
      return template(data);
    }
    return url;
  }

  useEffect(() => {
    if(allChecked(contractChecked) && dsgvoChecked &&  returnPoliciesChecked && onChecked) {
      onChecked(true);
    } else if(onChecked) {
      onChecked(false);
    }
  }, [contractChecked, dsgvoChecked, returnPoliciesChecked])

  useEffect(() => {
    // Fetch all States for the contracts
    if (allContracts) {
        for (const contract of allContracts) {
            if (contract.targetField) {
            contract.loading = true;
            ProjectService.getOrderApprovementField(project, company, contract).then((result) => {
                contract.checked = result === "true" || result === true;
                contract.loading = false;
                setContractChecked([...contractChecked]);
            }).catch(() => {
                contract.loading = false;
                setContractChecked([...contractChecked]);
            })
            }
        }
    }
  }, []);

  const getShoppingCartTransformed = (quot) => {
    var item = []
    if (quot && quot.positions) {
      for (const product of quot.positions.filter((p) => !p.article || (p.article && getCustomField(p.article, "groupArticle", false, "False") != "True"))) {
        item.push(product);
      }
    }
    return item;
  }



  return (
    <div>
      <br/>
      {project && project.quotations && project.quotations
          .filter((q) => getShoppingCartTransformed(q).length > 0)
          .map((q) => {
            return <Row style={{padding: 0}}>
              <Col lg={24} xl={24} md={24} sm={24} xs={24} style={colStyle}>
                <h2>{q.Subject}</h2>
                <ShoppingCart showPreview={true} showTitle={false} deletable={false}
                              hideGroupArticles={true}
                              getShoppingCartTransformed={() => getShoppingCartTransformed(q)}
                              showPaymentOptions={true} choosePaymentOptions={true} setPaymentOption={setPaymentOption}
                />
                {loading && <Loader />}
              </Col>
            </Row>
          })}

      {getCustomField(project, "Mainproject") == "True" && <div style={{paddingTop: 30}}>
        {project && project.SubProjects && project.SubProjects.length > 0 && <h3>Hier noch alle zugehörigen Projekte</h3>}
        {project && project.SubProjects && project.SubProjects.map((sub) => {
          return <div>
            <h3>Subprojekt #{sub.Number}</h3>
            {sub.quotations && sub.quotations.map((q) => {
              return <Row style={{padding: 0}}>
                <Col lg={24} xl={24} md={24} sm={24} xs={24} style={colStyle}>
                  <h2>{q.Subject}</h2>
                  <ShoppingCart showPreview={true} showTitle={false} deletable={false}
                                getShoppingCartTransformed={() => getShoppingCartTransformed(q)}
                                showPaymentOptions={true} choosePaymentOptions={true} setPaymentOption={setPaymentOption}
                  />
                  {loading && <Loader />}
                </Col>
              </Row>
            })}</div>})}
      </div>}

      <br/><br/>
      {!removeChecks && <div style={{paddingTop: 30}}>

        {contractChecked.map((c, i) => {

            const isDisabled = () => {
                return approved || c.mode === "readonly" || (c.mode === "checkonly" && c.checked);
            }

            const onClick = () => {
                if (isDisabled()) return;
                contractChecked[i].checked=!contractChecked[i].checked;
                onContractCheckedChanged(contractChecked[i], contractChecked[i].checked);
            }

          return <div style={{marginBottom: 10}}><div onClick={() => onClick()} style={{cursor: "pointer",fontSize: 16}}>
                    {!c.loading && <Checkbox disabled={isDisabled()}  checked={c.checked}   />}
                    {c.loading && <Spin size={12} />}
                    <CheckBoxText>{c.title}</CheckBoxText>&nbsp;&nbsp;
                    <a target={"_blank"} href={replaceUrl(c.url)}>{c.readMore ? c.readMore : "(mehr lesen)"}</a>
                  </div>
                  {c.checkedWarning && c.checked && <div style={{paddingLeft: 20, fontSize: 16, color: "red"}} dangerouslySetInnerHTML={{__html: c.checkedWarning}}></div>}
                  {c.description && <div style={{paddingLeft: 20, fontSize: 14}} dangerouslySetInnerHTML={{__html: c.description}}></div>}
                  {c.checked && c.upload && <div style={{color: "red"}}>Bitte laden Sie das unterschriebene Dokument hier hoch.</div>}
                  {c.checked && c.upload && <div style={{paddingTop: 10}}>
                    <UploadOrUploaded customer={company} project={project} />
                  </div>}
                </div>
        })}
        <div onClick={() => setDsgvoChecked(!dsgvoChecked)} style={{cursor: "pointer",fontSize: 16, marginBottom: 0}}><Checkbox disabled={approved} checked={dsgvoChecked}  /><CheckBoxText> Ich habe die Datenschutzrichtlinien zur Kenntnis genommen</CheckBoxText>  <a target={"_blank"} href={"https://www.agentur-id.de/datenschutz"}>(mehr lesen)</a></div>
        <div style={{fontSize: 16,marginLeft: 20}}>  <CheckBoxText>   Datenschutz ist uns wichtig </CheckBoxText> </div>
        <div onClick={() => setReturnPoliciesChecked(!returnPoliciesChecked)} style={{cursor: "pointer",fontSize: 16, marginBottom: 10}}><Checkbox disabled={approved}  checked={returnPoliciesChecked}  /> <CheckBoxText>Die geltende Widerrufsbelehrung finden Sie hier.</CheckBoxText>  <a target={"_blank"} href={"https://webconfig.agentur-id.de/widerruf/62975912f0b3af0013bb1bcc"}>(mehr lesen)</a></div>
      </div>
      }
      {!removeChecks && !validated && <div>
        <span style={{color: "red"}}>Bitte bestätigen Sie Ihre Bestellung, Datenschutzerklärungen, AGB´s, AV-Vertrag sowie Zahlungsweise.</span>
      </div>}
    </div>
  )
}

export default OrderApprovementComp;

const CheckBoxText = (props) => {
  return (
    <span style={{paddingLeft: 10}}>{props.children}</span>
  )
}
