import React from 'react';

const UploadOrUploaded = ({customer, project}) => {

    return (
        <div>

        </div>
    )}

export default UploadOrUploaded;