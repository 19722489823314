
import Https from './HttpsClient';
import {IRequestPagedQueryFilter, IRequestQueryFilter} from "./models/base/IRequest";
import {IPagedResponse, IResponse} from "./models/base/IResponse";
import {IPage} from "./models/base/IPage";

export abstract class BaseApi {
  protected mapQueryParams(query: any) {
    // return Object.keys(params)
    //   .map((paramKey) => `${paramKey}=${params[paramKey].toString()}`)
    //   .join('&');
    const params = [];
    for (const key of Object.keys(query)) {
      if (query[key]) {
        if (query[key] instanceof Array) {
          // @ts-ignore
          query[key].forEach((item) => {
            params.push(`${key.toString()}=${item}`);
          });
        } else {
          params.push(`${key.toString()}=${query[key]}`);
        }
      }
    }
    return params.join('&');
  }

  protected buildQueryParams(): string {
    return '';
  }

  protected requestGet<T>(
    endpoint: string,
    queryFilter?: IRequestQueryFilter,
  ): Promise<T> {
    return new Promise<any>((resolve, reject) => {
      if (queryFilter && queryFilter.query) {
        endpoint = endpoint + '?' + this.mapQueryParams(queryFilter.query);
      }

      Https.get(endpoint)
        .then((result) => {
          if (result) {
            resolve((result as IResponse<T>).content || result);
          }
          resolve(null);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  protected requestGetPaged<T>(
    endpoint: string,
    queryFilter?: IRequestPagedQueryFilter,
  ): Promise<IPage<T>> {
    return new Promise((resolve, reject) => {
      if (queryFilter && queryFilter.query) {
        endpoint += '?' + this.mapQueryParams(queryFilter.query);
      }

      Https.get(endpoint)
        .then((result: IPagedResponse<T>) => {
          resolve({
            pagination: {
              pageSize: result.pagination.pageSize,
              offset: result.pagination.offset,
              totalItems: result.pagination.totalItems,
            },
            content: result.content,
          } as IPage<T>);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  protected requestPost<T>(
      endpoint: string,
      data: T
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      Https.post(endpoint, data as any)
      .then((result) => {
        resolve((result as IResponse<T>).content || result);
      })
      .catch((error) => {
        reject(error);
      });
    });
  }

  protected requestPut<T>(
      endpoint: string,
      data: T
  ): Promise<T> {
    return new Promise<any>((resolve, reject) => {
      Https.put(endpoint, data as any)
      .then((result) => {
        if (result) {
          resolve((result as IResponse<T>).content || result);
        }
        resolve(null);
      })
      .catch((error) => {
        reject(error);
      });
    });
  }

  protected requestDelete<T>(
      endpoint: string
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      Https.delete(endpoint)
      .then((result) => {
        resolve((result as IResponse<T>).content || result);
      })
      .catch((error) => {
        reject(error);
      });
    });
  }

  protected requestPatch<T>(
    endpoint: string,
    data: T
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      Https.patch(endpoint, data as any)
        .then((result) => {
          resolve((result as IResponse<T>).content || result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

}
