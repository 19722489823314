import React from 'react';
import { Link } from 'react-router-dom';
import siteConfig from '@iso/config/site.config';
import { IoIosFlash } from 'react-icons/io';

export default ({ collapsed }) => {
  return (
    <div className="isoLogoWrapper">
      {collapsed ? (
        <div>
          <h3>
            <Link to="/dashboard">
              <img src={"/images/logo_cockpit.png"} alt="logo" width="30" />
            </Link>
          </h3>
        </div>
      ) : (
        <div>
          <Link to="/dashboard"><span style={{color: "#fff", fontSize: 20, fontWeight: "600"}}>{siteConfig.siteName}</span></Link>
        </div>
      )}
    </div>
  );
};
