import * as axios from 'axios';
import Auth0 from '@iso/containers/Authentication/Auth0/Auth0';
import { API_URL } from '../config';

//const API_URL = window.location.protocol+"//"+window.location.host;

//
// export const ngrokUrl = 'https://c20819da.ngrok.io';
//
// const externalUrl = 'https://laufen-dev.adrodev.de';
// const devUrl = 'https://azapp-rgpiotcore-webapi-dev.azurewebsites.net';
// const localUrl = `${ngrokUrl}`;
const serverUrl = API_URL;
const fallback = { languageTag: 'en', isRTL: false };
export const preferredLanguage = 'en';

export function replaceURL(url) {
  let hostname =
    window.location.hostname.split('.').length > 1
      ? window.location.hostname.split('.').slice(1).join('.')
      : window.location.hostname;
  let port = window.location.port;
  return url.replace('$HOSTNAME', hostname).replace('$PORT', port);
}

export const https = axios.create({
  baseURL: replaceURL(serverUrl),
  timeout: 360000,
  headers: { 'Content-Type': 'application/json' },
  params: {},
});
https.defaults.timeout = 360000;

export default class Https {
  static dispatch = null;
  static getState = null;

  static call = async (method, path, params = {}, replaceBaseUrl) => {
    let accessToken = localStorage.getItem('access_token');
    try {
      const requestData =
        method === 'get' || method === 'delete'
          ? { params: JSON.stringify(params) }
          : { data: JSON.stringify(params) };
      let res;

      // console.log('requestData = ' + new Date() +JSON.stringify(requestData));
      if (replaceBaseUrl) {
        res = await https({
          baseURL: serverUrl + replaceBaseUrl,
          method,
          url: path,
          ...requestData,
        });
      } else {
        res = await https({
          method,
          headers: {
            Authorization: 'Bearer ' + accessToken,
          },
          url: path,
          ...requestData,
        });
      }

      // console.log(`[${method.toUpperCase()}] ${path} - Success - xx`, new Date(), res.data);
      if (res.status === 200) {
        return res.data;
      } else {
        // Throw so error will be logged below

        throw {
          status: res.status,
          statusText: res.statusText,
          error: res.data,
        };
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status == 401 || err.response.status === 403) {
          Auth0.reAuthenticate();
        }
      }
      console.log(
        `[${method.toUpperCase()}] ${path} - Error - ${
          err.message || err.statusText
        }`
      );
      console.log(`[${method.toUpperCase()}] ${path} - Error: `, err);
      // use only throw if no stacktrace is wanted
      throw err;
    }
  };

  static get = async (path, params = {}, replaceBaseUrl) => {
    return this.call('get', path, params, replaceBaseUrl);
  };

  static post = async (path, params = {}, replaceBaseUrl) => {
    return this.call('post', path, params, replaceBaseUrl);
  };

  static put = async (path, params = {}, replaceBaseUrl) => {
    return this.call('put', path, params, replaceBaseUrl);
  };

  static delete = async (path, params = {}, replaceBaseUrl) => {
    return this.call('delete', path, params, replaceBaseUrl);
  };

  static patch = async (path, params = {}, replaceBaseUrl) => {
    return this.call('patch', path, params, replaceBaseUrl);
  };

  static setAccessToken = (accessToken) => {
    https.defaults.headers.Authorization = 'Bearer ' + accessToken;
  };
}

export { serverUrl };
