import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import appActions from '@iso/redux/app/actions';
const {
  toggleOpenDrawer,
  changeOpenKeys,
  changeCurrent,
  toggleCollapsed,
} = appActions;
import { Link, useRouteMatch } from 'react-router-dom';
import ImageCellView from './ImageCell';
import DeleteCell from './DeleteCell';
import EditableCell from './EditableCell';
import FilterDropdown from './FilterDropdown';
import Button from "../uielements/button";
import moment from "moment";

import './HelperCell.css';

const DateCell = (data, format) => <span>{format ? moment(data).format(format) : data.toLocaleString()}</span>;
const ImageCell = src => <ImageCellView src={src} />;
const LinkCell = (link, href, colored) => {
  const coloredstyle = colored === false ? {color: "#555"} : {};
  return <a dangerouslySetInnerHTML={{__html: link}} style={coloredstyle} href={href ? href : '#'} target={href.includes("http") ? "_blank" : "_self"}></a>;
}
const TextCell = text => <p dangerouslySetInnerHTML={{__html: text}}></p>;
const MoneyCell = text => <p>{new Number(text).toFixed(2)} &euro;</p>;
const DetailLinkCell = (text, link, mode=null) => {
  if (mode == null) {
    mode = "primary";
  }
  const dispatch = useDispatch();
  return (
    <Link  to={link+text}>
    <Button size={"small"} type={mode} onClick={() => {
      dispatch(changeCurrent(["mailbox"]));
    }}> {"Details "}</Button>
    </Link>
    )

}

const TrafficLight = (text, link, mode) => {
  if (text == "1") {
    return <div className={"led-green"}></div>;
  }
  else if (text == "2") {
    return <div className={"led-orange"}></div>;
  }
  else if (text == "3") {
    return <div className={"led-red"}></div>;
  }

  return <div className={"led-green"}></div>;
  /*
  <div>
    <div style={{borderRadius: "100%", width: "12px", height: "12px", padding: "10px", backgroundColor:
          (text == "1" ? "green" : text == "2" ? "orange" : text == "3" ? "red" : "green"), textShadow: "0 0 20px #f00, 0 0 30px #f00, 0 0 40px #f00, 0 0 50px #f00, 0 0 60px #f00, 0 0 70px #f00, 0 0 80px #f00"}}></div>
  </div>*/
}

const StatusCell = text =>  {
  var background = "green";
  if(text == "Agentur Bearbeitet Anfrage" || text == "Warte auf Daten") {
    background = "red";
  }
  return (
    <div style={{color: "white", background: background, textAlign: "center",fontWeight: "normal", borderRadius: 5, padding: 10}}>{text}</div>
  )
}

export {
  DateCell,
  ImageCell,
  LinkCell,
  TextCell,
  TrafficLight,
  MoneyCell,
  EditableCell,
  DeleteCell,
  FilterDropdown,
  StatusCell,
  DetailLinkCell,
};
