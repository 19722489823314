import {BaseApi} from "./BaseApi";
import IProject from "./models/IProject";
import IProposal from "./models/IProposal";

export class DBService extends BaseApi {


  public getAdmin(): Promise<any> {
    return this.requestGet('/dccockpit/db/admin');
  }

  saveAdmin(config: any) {
    return this.requestPut('/dccockpit/db/admin', config);
  }

}

export default new DBService();
