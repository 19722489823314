import React, { useEffect } from 'react';
import Auth0 from './Auth0';
import authActions from '@iso/redux/auth/actions';
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
const Auth0Callback = () => {
  const dispatch = useDispatch();
  const histroy = useHistory();
  useEffect(() => {
    console.log(histroy.location)
    console.log(histroy.length)
    console.log("Auth0Callback ", window.location.href)
    console.log("Auth0Callback ",  window.location.hash)
    console.log("Auth0Callback hash", histroy.location.hash)
    // alert("tschö " + window.location.href)
    console.log("tschööööö")
    var accessToken = Auth0.handleAuthentication(histroy.location.hash);
    dispatch(authActions.login(accessToken));

  }, []);

  return <p>Loading ...</p>;
};

export default Auth0Callback;
