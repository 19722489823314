import { store } from './store';
import appActions from '@iso/redux/app/actions';
import DBService from "../services/DBService";

export default () =>
  new Promise(() => {
    // store.dispatch(authActions.checkAuthorization());
    DBService.getAdmin().then((admin) => {
        if (admin) {
            if (admin.maintenanceMode === true) {
                store.dispatch(appActions.maintenanceMode());
            }

        }
    });
  });
